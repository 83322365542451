import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import Section from "../layout/section";

import agencyService from "../../services/agencyService";
import MessengerInfoConfirmation from "../tables/messenger-info-confirmation";
import Modal from "../modal";
import UserMessage from "../user-message";

export default function ReviewInformation({
  agencyData,
  handleNav,
  handleLogout,
}) {
  const [infoConf, setInfoConf] = useState("");
  // const [customerService, setCustomerService] = useState(false);
  const [showCustServiceMessage, setShowCustServiceMessage] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  // const handleCustomerService = () => {
  //   if (infoConf == "correct") {
  //     handleSubmit();
  //   } else {
  //     setShowCustServiceMessage(true);
  //   }
  //   handleSubmit();
  // };
  let date = moment()
    .tz("America/New_York")
    .format("dddd, MMMM Do YYYY, h:mm:ss a");

  const handleIncorrectInformation = (agency) => {
    agencyService.saveAgencyData(agency._id, {
      messengerData: {
        ...agency.messengerData,
        eventDates: {
          ...agency.messengerData.eventDates,
          awaitingInformation: date,
        },
        informationUpdate: true,
      },
    });
    setShowCustServiceMessage(true);
  };

  const handleCorrectInformation = (agency) => {
    agencyService.saveAgencyData(agency._id, {
      messengerData: {
        ...agency.messengerData,
        eventDates: {
          ...agency.messengerData.eventDates,
          informationApproved: date,
        },
        informationUpdate: false,
      },
    });
    handleNav("Review-Information", "Sign-Documents");
  };

  const handleSubmit = async () => {
    await agencyData.forEach((agency) => {
      if (infoConf === "correct") {
        handleCorrectInformation(agency);
      } else {
        handleIncorrectInformation(agency);
      }
    });
  };

  const [confirmedInfoAccuracy, setConfirmedInfoAccuracy] = useState(false);
  const [awaitingCustService, setAwaitingCustService] = useState(false);
  useEffect(() => {
    document.title = `SembraCare Agency Portal - Review Information`;

    if (agencyData) {
      // handleReviewed();
      agencyData.map((agency) => {
        if (
          // agency.messengerData &&
          // agency.messengerData.reviewed &&
          agency.messengerData &&
          agency.messengerData.informationUpdate
        ) {
          // console.log("Reviewed and Incorrect Information");
          setAwaitingCustService(true);
        } else if (
          // agency.messengerData &&
          // agency.messengerData.reviewed &&
          agency.messengerData &&
          agency.messengerData.informationUpdate === false
        ) {
          // console.log("Reviewed and Accurate");
          setConfirmedInfoAccuracy(true);
        } else {
          // console.log("Not reviewed");
          setConfirmedInfoAccuracy(false);
          setAwaitingCustService(false);
        }
      });
    }
  });

  return (
    <main>
      <Section
        title="Review Information"
        body={
          <div>
            <p>
              Please review the provider information below. After review, please
              choose one of the options in order to continue. If your
              information is correct you can continue on to sign your documents.
              If information is incorrect, we will contact you to update your
              inforamtion before you continue on to sign your document(s).
            </p>
          </div>
        }
      />

      <MessengerInfoConfirmation agencyData={agencyData} />

      {!confirmedInfoAccuracy &&
      !awaitingCustService &&
      !confirmedInfoAccuracy ? (
        <div className="review-info-conf">
          <div className="form-group">
            <label>
              <input
                type="radio"
                name="review-radio"
                value="correct"
                onChange={() => setInfoConf("correct")}
              ></input>
              The above information is <strong>CORRECT</strong>- I am ready to
              sign my document(s)
            </label>
          </div>
          <div className="form-group">
            <label>
              <input
                type="radio"
                name="review-radio"
                value="incorrect"
                onChange={() => setInfoConf("incorrect")}
              ></input>
              The above information is <strong>INCORRECT</strong>- I want a
              SembraCare representative to contact me.
            </label>
          </div>
          <div className="btn-bar">
            <button
              className={infoConf ? "sc-btn" : "disabled-btn"}
              onClick={handleModal}
            >
              Next
            </button>
          </div>
        </div>
      ) : (
        <>
          {awaitingCustService ? (
            <UserMessage
              message={`You previously chose the information above is INCORRECT, we are working on updating your information. SembraCare will be contacting you shortly.`}
              variant="error"
            />
          ) : (
            <>
              <UserMessage
                message={`Your information was reviewed and confirmed as accurate. Please continue to "Sign Documents"`}
                variant="error"
              />
              <div className="btn-bar">
                <button
                  onClick={(e) => handleNav("", "Sign-Documents")}
                  className="sc-btn"
                >
                  Next
                </button>
              </div>
            </>
          )}
        </>
      )}

      {showModal && (
        <Modal
          modalBody={
            <div>
              {!showCustServiceMessage ? (
                <div>
                  {infoConf == "correct" ? (
                    <p>
                      The information I reviewed is <strong>CORRECT</strong> and
                      I am ready to sign my documents
                    </p>
                  ) : (
                    <p>
                      The information I reviewed is <strong>INCORRECT</strong>{" "}
                      and needs to be updated with a SembraCare representitive?
                    </p>
                  )}
                </div>
              ) : (
                <>
                  <p>
                    Thank you for reviewing your information. Our team will
                    contact you within 2 business days.
                  </p>
                  <p>Click "Logout" to log out now</p>
                  <button className="sc-btn" onClick={handleLogout}>
                    Logout
                  </button>
                </>
              )}
              {!showCustServiceMessage && (
                <div className="btn-group">
                  <button className="sc-btn" onClick={handleSubmit}>
                    Confirm
                  </button>
                  <button className="sc-btn" onClick={closeModal}>
                    Cancel
                  </button>
                </div>
              )}
            </div>
          }
          modalTitle="Review Confirmation"
        />
      )}
    </main>
  );
}
