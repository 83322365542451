import React, { useState, useEffect } from "react";
import Modal from "../modal";
import TextInput from "../inputs/text-input";
import Button from "../button";

export default function Section({ title, body }) {
  return (
    <section>
      <h2>{title}</h2>
      <div className="section-body">{body}</div>
    </section>
  );
}

// const Section = React.forwardRef((ref) => {
//   return (
//     <section ref={ref}>
//       <h2>{title}</h2>
//       <div className="section-body">{body}</div>
//     </section>
//   );
// });

// export default Section;
