import http from "./httpService";
import apiUrl from "../config.json";
import authService from "./authService";

export async function getAgencyID(sembraKey) {
  // const apiEndpoint = apiUrl.apiUrl + "/getAgencyID";
  const apiEndpoint = apiUrl.apiUrl + `document-portal/getAgency-id/${sembraKey}`;

  return await http.post(
    apiEndpoint,
    { sembraKey: sembraKey },
    {
      headers: { Authorization: `Bearer ${authService.getJwt()}` },
    }
  );

  //   console.log(test);
}

export function getAgencyData() {
  const apiEndpoint = apiUrl.apiUrl + "document-portal/get-agency-data";
  return http.post(
    apiEndpoint,
    { username: authService.getCurrentUser() },
    {
      headers: { Authorization: `Bearer ${authService.getJwt()}` },
    }
  );
}

export async function saveAgencyData(agencyID, saveData) {
  const apiEndpoint = apiUrl.apiUrl + "document-portal/save-agency-data";

  const test = await http.post(
    apiEndpoint,
    { id: agencyID, saveData: saveData },
    {
      headers: { Authorization: `Bearer ${authService.getJwt()}` },
    }
  );
  // return await http.post(
  //   apiEndpoint,
  //   { id: agencyID, saveData: saveData },
  //   {
  //     headers: { Authorization: `Bearer ${authService.getJwt()}` },
  //   }
  // );
}

export function addValidAgencyUser(username, agencyID) {
  const apiEndpoint = apiUrl.apiUrl + "document-portal/add-valid-agency-user";
  const userJWT = localStorage.getItem("token");

  return http.post(
    apiEndpoint,
    { username: username, id: agencyID },
    {
      headers: { Authorization: `Bearer ${userJWT}` },
    }
  );
}

export default {
  getAgencyID,
  getAgencyData,
  saveAgencyData,
  addValidAgencyUser,
};
