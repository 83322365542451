import React, { useState, useEffect, useRef } from "react";
import { Routes, Route } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
// import history from 'history/browser';
import authService from "./services/authService";
import agencyService from "./services/agencyService";
import docService from "./services/docService";
import requiredDocList from "./assets/objects/requiredDocuments";
import Loader from "./components/loader";

// Layout
import Header from "./components/layout/header";
import Footer from "./components/layout/footer";
import Nav from "./components/layout/nav";

// Pages
import NewAgencyLogin from "./components/pages/login/login";
import ForgotUsername from "./components/pages/login/forgot-credentials/forgot-username-form";
import ResetPassword from "./components/pages/login/forgot-credentials/reset-password";
import LegacySignup from "./components/pages/login/legacy-signup";
import Welcome from "./components/pages/welcome/welcome";
import ReviewInformation from "./components/pages/review-information";
import Documents from "./components/pages/documents/sign-documents";
import FinalDocuments from "./components/pages/documents/final-documents";
import Success from "./components/pages/success";
import Modal from "./components/modal";
import UserMessage from "./components/user-message";

function App() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [viewportWidth, setViewportWidth] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const [navPages, setNavPages] = useState([
    { pageName: "Welcome", isActive: true, isComplete: false },
    { pageName: "Review-Information", isActive: false, isComplete: false },
    { pageName: "Sign-Documents", isActive: false, isComplete: false },
  ]);
  const [user, setUser] = useState();
  const [agencyData, setAgencyData] = useState();
  const [userUpdate, setUserUpdate] = useState();
  const [upToDateDocs, setUpToDateDocs] = useState([]);
  const [taxIDFullList, setTaxIDFullList] = useState([]);
  const [attnDocs, setAttnDocs] = useState([]);
  // console.log("Attn docs: ", attnDocs);

  // will be called whenever window size changes
  window.addEventListener("resize", function () {
    setViewportWidth(window.innerWidth);
  });

  const handleNav = (completePage, activePage) => {
    let newNavArray = [...navPages];
    let updatedPages = newNavArray.map((navItem) => {
      if (navItem.pageName === completePage) {
        return { ...navItem, isActive: false, isComplete: true };
      }

      if (navItem.pageName === activePage) {
        return { ...navItem, isActive: true, isComplete: false };
      }

      return navItem;
    });
    setNavPages(updatedPages);
    setUserUpdate(!userUpdate);
    navigate(`/${activePage}`);
  };

  let [isNavbarExpanded, setIsNavbarExpanded] = useState(false);

  const [counterDocs, setCounterDocs] = useState([]);

  // const pullCounterDocs = async (agency, taxIdList) => {
  //   let countersingedDocs = [];
  //   let newCounterObj = {};
  //   let pulledDocs = await docService.getCounterDocs(
  //     agency._id,
  //     agency.companyData.name
  //   );

  //   pulledDocs.map((doc) => {
  //     if (doc.counterDoc) {
  //       // let counterDocObj = {
  //       //   provider: doc.docCompany,
  //       //   documentTitle: doc.docTitle,
  //       // }
  //       // newCounterObj = counterDocObj;

  //       // countersingedDocs.push(newCounterObj);
  //       countersingedDocs.push(doc);
  //     }
  //   });

  //   // taxIdList.map((id) => {
  //   //   console.log(id);
  //   //   newCounterObj.taxId = id.taxID;
  //   // })

  //   setCounterDocs(countersingedDocs);
  // };

  const pullCounterDocs = async (agency, taxIdList) => {
    let countersingedDocs = [];
    let newCounterObj = {};
    try {
      let pulledDocs = await docService.getCounterDocs(
        agency._id,
        agency.companyData.name
      );

      if (pulledDocs) {
        pulledDocs.map((doc) => {
          if (doc.counterDoc) {
            countersingedDocs.push(doc);
          }
        });
      }
      setCounterDocs(countersingedDocs);
    } catch (e) {
      // console.error(e);
    }
  };

  async function pullAgencyData() {
    try {
      const { data: agencyDataReturn } = await agencyService.getAgencyData();
      setAgencyData(agencyDataReturn);

      agencyDataReturn.map((agency) => {
        // REFACTOR THIS
        // try {
        if (agency.messengerData.participationDocSigned) {
          // console.log("welcome terms accepted and review information is correct")
          setNavPages([
            { pageName: "Welcome", isActive: false, isComplete: true },
            {
              pageName: "Review-Information",
              isActive: false,
              isComplete: true,
            },
            { pageName: "Sign-Documents", isActive: false, isComplete: true },
          ]);
        } else if (agency.messengerData.informationUpdate === false) {
          // console.log("welcome terms accepted and review information is correct")
          setNavPages([
            { pageName: "Welcome", isActive: false, isComplete: true },
            {
              pageName: "Review-Information",
              isActive: false,
              isComplete: true,
            },
            { pageName: "Sign-Documents", isActive: true, isComplete: false },
          ]);
        } else if (agency.messengerData.welcomeTermsAccepted) {
          // console.log("welcome terms are accepted");
          setNavPages([
            { pageName: "Welcome", isActive: false, isComplete: true },
            {
              pageName: "Review-Information",
              isActive: true,
              isComplete: false,
            },
            { pageName: "Sign-Documents", isActive: false, isComplete: false },
          ]);
        } else {
          // console.log("This needs to be refactored")
        }
        // } catch (e) {
        // console.log("This needs to be refactored")
        // console.error(e)
        // }
      });

      let reqDocList = [];
      let upToDateDocsList = [];
      //Now set the TaxID Object List
      let taxIDFullListHold = [];
      agencyDataReturn.map((agency) => {
        //Find all Unique TaxIDs for Agency
        let taxIDList = [];

        agency.licenseData.map((license) => {
          license.npiList.map((npi) => {
            if (taxIDList.indexOf(npi.taxID) === -1) taxIDList.push(npi.taxID);
          });
        });

        requiredDocList.map((reqDoc) => {
          let docHold = {
            docId: reqDoc._id,
            docName: reqDoc.docName,
            docTitle: reqDoc.docTitle,
            agencyName: agency.companyData.name,
            agencyPhone: agency.companyData.phone,
            agencyEmail: agency.companyData.email,
            agencyAddress: {
              address1: agency.companyData.address.address1,
              city: agency.companyData.address.city,
              state: agency.companyData.address.state,
              zip: agency.companyData.address.zip,
            },
          };

          //checks to see if required Document needs each TaxID addressed
          if (reqDoc.docTaxID) {
            taxIDList.map((taxID) => {
              // try {
              docHold.docTaxID = taxID;
              if (agency.contractInfo.MessengerParticipation.length == 0)
                reqDocList.push(docHold);
              else {
                let addTaxIDDoc = true;
                let docIDHold = "";
                agency.contractInfo.MessengerParticipation.map(
                  (messengerContract) => {
                    if (messengerContract.taxID == taxID) {
                      addTaxIDDoc = false;
                      docIDHold = messengerContract.Messenger;
                    }
                  }
                );

                if (addTaxIDDoc) reqDocList.push(docHold);
                else {
                  docHold.docID = docIDHold;
                  upToDateDocsList.push(docHold);
                }
              }
              // } catch {}
            });
          } else {
            if (reqDoc.docName == "UpdatedCSA") {
              if (agency.contractInfo.UpdatedCSA)
                docHold.docID = agency.contractInfo.UpdatedCSA;
              upToDateDocsList.push(docHold);
            }
          }

          if (agency.contractInfo) {
            if (!agency.contractInfo[reqDoc.docName]) reqDocList.push(docHold);
          }
        });
        // console.log("Req doc list: ", reqDocList);

        taxIDList.map((taxID) => {
          let taxIDHold = {};
          taxIDHold.taxID = taxID;
          taxIDHold.taxIDData = [];

          agency.licenseData.map((license) => {
            license.npiList.map((npi) => {
              if (npi.taxID == taxID) {
                let taxIDDataObject = {};
                taxIDDataObject.licenseNumber = license.licenseNumber;
                taxIDDataObject.type = npi.taxonomy;
                taxIDDataObject.npi = npi.npi;
                taxIDHold.taxIDData.push(taxIDDataObject);
              }
            });
          });
          taxIDFullListHold.push(taxIDHold);
          // pullCounterDocs(agencyDataReturn[0], taxIDList);
        });
      });

      setIsLoading(false);
      setUpToDateDocs(upToDateDocsList);
      setTaxIDFullList(taxIDFullListHold);
      pullCounterDocs(agencyDataReturn[0], taxIDFullListHold);
      setAttnDocs(reqDocList);
    } catch (e) {
      console.log("ERROR: ", e);
    }
  }

  const handleUserUpdate = () => {
    setUserUpdate(!userUpdate);
  };

  const handleLogout = () => {
    authService.logout();
    window.location = "/";
  };

  const showCollapsedNav = () => {
    setIsNavbarExpanded(!isNavbarExpanded);
    const navbarToggle = document.querySelector("#navbar-toggle");
    navbarToggle.setAttribute("aria-expanded", isNavbarExpanded);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const handleLogoutModal = () => {
    setShowLogoutModal(true);
  };

  const [idleState, setIdleState] = useState("Active");
  const onIdle = () => {
    setIdleState("Idle");
  };

  const onActive = () => {
    setIdleState("Active");
  };

  useIdleTimer({
    onIdle,
    onActive,
    // timeout: 10_000, // testing timer
    timeout: 1000 * 60 * 15, // prod timer
  });

  useEffect(() => {
    try {
      const userHold = authService.getCurrentUser();
      setUser(userHold);
      // pullAgencyData();
      // if (userHold) {
      pullAgencyData();
      if (idleState === "Idle") {
        handleLogout();
      }
      // }
    } catch (e) {
      console.error("Error in Usser Effect", e);
    }
  }, [userUpdate, idleState]);

  return (
    <div className="App">
      <>
        {!user ? (
          <div className="login-wrapper">
            <Routes>
              <Route
                path="/"
                element={
                  <NewAgencyLogin
                    onUserUpdate={handleUserUpdate}
                    handleNav={handleNav}
                    handleShowPassword={handleShowPassword}
                    showPassword={showPassword}
                  />
                }
              ></Route>
              <Route
                path="/forgot-username"
                element={<ForgotUsername onUserUpdate={handleUserUpdate} />}
              ></Route>
              <Route
                path="/reset-password"
                element={
                  <ResetPassword
                    onUserUpdate={handleUserUpdate}
                    handleShowPassword={handleShowPassword}
                    showPassword={showPassword}
                  />
                }
              ></Route>
              <Route
                path="/legacy/:id"
                element={
                  <LegacySignup
                    onUserUpdate={handleUserUpdate}
                    handleShowPassword={handleShowPassword}
                    showPassword={showPassword}
                    handleNav={handleNav}
                  />
                }
              ></Route>
            </Routes>
          </div>
        ) : (
          <>
            {isLoading && <Loader />}

            <Header
              showCollapsedNav={showCollapsedNav}
              handleLogout={handleLogout}
              handleLogoutModal={handleLogoutModal}
            />
            <div className="content-wrapper">
              <Nav
                viewportWidth={viewportWidth}
                isNavbarExpanded={isNavbarExpanded}
                navPages={navPages}
                setIsNavbarExpanded={setIsNavbarExpanded}
              />
              <Routes>
                <Route
                  path={navPages[0].pageName}
                  element={
                    <Welcome
                      agencyData={agencyData}
                      handleNav={handleNav}
                      navigate={navigate}
                    />
                  }
                ></Route>
                <Route
                  path={navPages[1].pageName}
                  element={
                    <ReviewInformation
                      agencyData={agencyData}
                      handleNav={handleNav}
                      navigate={navigate}
                      handleLogout={handleLogout}
                    />
                  }
                ></Route>
                <Route
                  path={navPages[2].pageName}
                  element={
                    <Documents
                      agencyData={agencyData}
                      attnDocs={attnDocs}
                      upToDateDocs={upToDateDocs}
                      handleNav={handleNav}
                      navigate={navigate}
                      handleUserUpdate={handleUserUpdate}
                      taxIDFullList={taxIDFullList}
                    />
                  }
                ></Route>
                <Route
                  path={"/success"}
                  element={<Success handleLogout={handleLogout} />}
                ></Route>
                <Route
                  path={"/final-documents"}
                  element={
                    <FinalDocuments
                      upToDateDocs={upToDateDocs}
                      taxIDFullList={taxIDFullList}
                      counterDocs={counterDocs}
                    />
                  }
                ></Route>
                {/* <Route path={navPages[0].pageName} element={<Welcome handleNav={handleNav} history={history} />}></Route>
              <Route path={navPages[1].pageName} element={<ReviewInformation agencyData={agencyData} handleNav={handleNav} />} ></Route>
              <Route path={navPages[2].pageName} element={<Documents agencyData={agencyData} attnDocs={attnDocs} upToDateDocs={upToDateDocs} />}></Route> */}
                {/* <Route path={navPages[3].pageName} element={<AdditionalPage2 />}></Route>
              <Route path={navPages[4].pageName} element={<AdditionalPage3 />}></Route>
              <Route path={navPages[5].pageName} element={<AdditionalPage4 />}></Route> */}

                <Route path="/" element="">
                  404
                </Route>
              </Routes>
            </div>
            <Footer
              handleLogout={handleLogout}
              handleLogoutModal={handleLogoutModal}
            />
          </>
        )}
        {showLogoutModal && (
          <Modal
            modalTitle="Logout Confirmation"
            modalBody={
              <>
                {attnDocs.length >= 1 && (
                  <>
                    <UserMessage
                      message="You have unsigned documents!"
                      variant="error"
                    />
                    <hr />
                  </>
                )}
                <>
                  <p>Are you sure you want to logout?</p>
                  <div className="btn-group">
                    <button className="sc-btn" onClick={handleLogout}>
                      Logout
                    </button>
                    <button
                      className="sc-btn"
                      onClick={() => setShowLogoutModal(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </>
              </>
            }
          />
        )}
      </>
    </div>
  );
}

export default App;
