import React from "react";
// import NavMenu from "../assets/images/icons/nav-menu.svg";
import NavMenu from "../../assets/images/icons/nav-menu.svg";

export default function CollapsedNav({ showCollapsedNav }) {
  return (
    <button
      type="button"
      id="navbar-toggle"
      aria-controls="navbar-menu"
      aria-label="Toggle menu"
      aria-expanded="false"
      onClick={showCollapsedNav}
    >
      <img src={NavMenu} alt="navigation menu" />
    </button>
  );
}
