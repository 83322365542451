import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { ImCheckboxUnchecked, ImCheckboxChecked } from "react-icons/im";
import Button from "../button";
import Contact from "../contact";

export default function Nav({
  isNavbarExpanded,
  setIsNavbarExpanded,
  navPages,
}) {
  const [showContactInfo, setShowContactInfo] = useState(false);

  const handleQuestions = () => {
    setShowContactInfo(!showContactInfo);
  };

  const handleLinkClick = () => {
    setIsNavbarExpanded(false);
  };

  return (
    <nav className="navbar">
      <div
        id="navbar-menu"
        className={isNavbarExpanded ? "collapsed-nav" : "nav"}
        aria-labelledby="navbar-toggle"
      >
        <ul className="navbar-links no-list-style">
          {navPages.map((page, index) => (
            <li key={index} className="navbar-item">
              {navPages[index].isComplete ? (
                <ImCheckboxChecked className="nav-icon" />
              ) : (
                <ImCheckboxUnchecked className="nav-icon" />
              )}
              <Link
                onClick={handleLinkClick}
                to={page.pageName}
                className={
                  !navPages[index].isComplete && !navPages[index].isActive
                    ? "disabled"
                    : "active"
                }
                disabled={navPages[index].isComplete ? false : true}
              >
                {page.pageName.replace("-", " ", (c) => c.toUpperCase())}
              </Link>
            </li>
          ))}
          <li>
            <Button label="Questions" handleClick={handleQuestions} />
          </li>
          {showContactInfo && <Contact />}
        </ul>
      </div>
    </nav>
  );
}
