import React from "react";

export default function Contact() {
  return (
    <address className="contact">
      <strong>Autumn Bridges</strong>
      <br />
      <strong>Email:</strong>{" "}
      <a href="mailto:autumn@sembracare.com">Autumn@sembracare.com</a> <br />
      <strong>Phone:</strong> (919) 376-1138
    </address>
  );
}
