import React, { useState } from "react";
import { TiDelete } from "react-icons/ti";

export default function Modal({ closeModal, modalTitle, modalBody }) {
  return (
    <div className="modal-wrapper">
      <div className="modal">
        {closeModal && (
          <button className="close-btn" onClick={closeModal}>
            <TiDelete />
          </button>
        )}
        <section>
          <h2>{modalTitle}</h2>
          <div className="modal-body">
            {modalBody}
          </div>
        </section>
      </div>
    </div>
  );
}
