import http from "./httpService";
import apiUrl from "../config.json";
import authService from "./authService";

export function registerLegacyUser(user) {
  const apiEndpoint = apiUrl.apiUrl + "document-portal/create-legacy-user";
  user.password = "";
  // localStorage.setItem("userInfo", user);
  localStorage.setItem("userInfo", JSON.stringify(user));
  return http.post(apiEndpoint, user);
}

export function assignLegacyUser(user) {
  const apiEndpoint = apiUrl.apiUrl + "document-portal/assignLegacyUser";

  return http.post(apiEndpoint, user);
}

export async function getUserData() {
  const apiEndpoint = apiUrl.apiUrl + "document-portal/get-user-data";

  const user = await http.post(
    apiEndpoint,
    { username: authService.getCurrentUser() },
    {
      headers: { Authorization: `Bearer ${authService.getJwt()}` },
    }
  );

  localStorage.setItem("userInfo", user.data[0]);
  return user;
}

export function saveUserData(username, userData, userJWT) {
  const apiEndpoint = apiUrl.apiUrl + "document-portal/saveUserData";

  return http.post(
    apiEndpoint,
    { username: username, userData: userData },
    {
      headers: { Authorization: `Bearer ${userJWT}` },
    }
  );
}

async function signup(username, password, fullName, mobileNumber, agencyCode) {
  const signupURL = "https://usermgmt.mysembra.com/aws-auth/register";

  const newCognitoUser = {
    Username: username,
    email: username,
    password: password,
    Organization: "SembraDocument",
    PhoneNumber: "+1" + mobileNumber.replace(/\D/g, ""),
    name: fullName,
    canBroadcast: false,
    agencycode: agencyCode,
  };

  try {
    return await http.post(signupURL, newCognitoUser).then(async (response) => {
      // return "New User Successfully Created";
      return {
        status: "success",
        successMessage: "New User Successfully Created",
      };
    });
  } catch (error) {
    console.error(error);
    return {
      status: "error",
      error: error,
    };
    return error;
    // if (error.response)
    //   if (error.response.data) return error.response.data.message;
    // return "Error in Signup";
  }
}

async function verifyCognito(username, veriCode) {
  const verificationURL =
    "https://usermgmt.mysembra.com/aws-auth/confirmSignup";

  const newVerificationCode = {
    username: username.toLowerCase(),
    code: veriCode,
  };

  try {
    return await http.post(verificationURL, newVerificationCode);
  } catch (error) {
    // console.log(error)
    return "Error in Verification";
  }
}

export default {
  registerLegacyUser,
  getUserData,
  saveUserData,
  signup,
  verifyCognito,
};
