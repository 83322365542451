import React, { useState, useEffect } from "react";

export default function TimedUserMessage({
  // type,
  message,
  variant,
  isVisible,
  hasIcon,
  icon,
}) {
  // console.log(message, variant, isVisible);
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    setShowMessage(true);

    const timeId = setTimeout(() => {
      setShowMessage(!setShowMessage);
    }, 5000);

    return () => {
      clearTimeout(timeId);
    };
  }, [isVisible]);

  return (
    <>
      {showMessage && message && (
        <div className={`user-message timed ${variant}`}>
          {hasIcon && icon}
          <p>{message}</p>
        </div>
      )}
    </>
  );
}
