export default function Button({
  disabledConditions,
  label,
  handleClick,
  type,
  isLinkStyle,
}) {
  return (
    <button
      className={`${disabledConditions ? "disabled-btn" : "sc-btn"} ${
        isLinkStyle ? "link-btn" : ""
      }`}
      disabled={disabledConditions ? true : false}
      type={type}
      onClick={handleClick}
    >
      {label}
    </button>
  );
}
