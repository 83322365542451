import http from "./httpService";
import apiUrl from "../config.json";
import authService from "./authService";

export async function createDoc(
  title,
  ownerName,
  companyName,
  mobileNumber,
  address
) {
  const createDocEndpoint = apiUrl.apiUrl + "document-portal/create-doc";

  const docInfo = {
    docUser: authService.getCurrentUser(),
    docTitle: title,
    docOwner: ownerName,
    docCompany: companyName,
    mobileNumber: mobileNumber.replace(/\D/g, ""),
    docDate: new Date().toLocaleDateString(),
    docAddress: address,
  };

  const document = await http.post(createDocEndpoint, docInfo, {
    headers: { Authorization: `Bearer ${authService.getJwt()}` },
  });

  // Send SMS if no error creating document
  let smsObj = {
    item: document,
    title: title,
    mobileNumber: mobileNumber,
  };
  sendSMS(smsObj);

  return document;
}

export async function resendDoc(docID, title, phoneNumber) {
  const apiEndpoint = apiUrl.apiUrl + "document-portal/resend-doc";
  const { data: docResponse } = await http.post(
    apiEndpoint,
    { docID: docID, title: title, phoneNumber: phoneNumber },
    {
      headers: { Authorization: `Bearer ${authService.getJwt()}` },
    }
  );
  // Send SMS if no error creating document
  let smsObj = {
    item: docResponse,
    title: title,
    mobileNumber: phoneNumber,
  };
  sendSMS(smsObj);
  return docResponse;
}

export async function checkDoc(docID) {
  // console.log(docID);
  const apiEndpoint = apiUrl.apiUrl + "document-portal/check-doc";
  const { data: docResponse } = await http.post(
    apiEndpoint,
    { docID: docID },
    {
      headers: { Authorization: `Bearer ${authService.getJwt()}` },
    }
  );
  return docResponse;
}

export async function getDoc(docID) {
  const apiEndpoint = apiUrl.apiUrl + "document-portal/get-doc";
  const doc = await http.post(
    apiEndpoint,
    { username: authService.getCurrentUser(), docID: docID },
    {
      headers: { Authorization: `Bearer ${authService.getJwt()}` },
    }
  );
  return doc.data;
}

export async function setFinalDoc(docId, finalDoc) {
  const apiEndpoint = apiUrl.apiUrl + "document-portal/set-final-doc";
  const docResponse = await http.post(
    apiEndpoint,
    { docId: docId, finalDoc: finalDoc },
    {
      headers: { Authorization: `Bearer ${authService.getJwt()}` },
    }
  );
  return docResponse;
}

export async function getCounterDocs(agencyId, agencyName) {
  const apiEndpoint = apiUrl.apiUrl + "document-portal/get-counter-doc";
  const counterDocs = await http.post(
    apiEndpoint,
    {
      username: authService.getCurrentUser(),
      agencyId: agencyId,
      agencyName: agencyName,
    },
    {
      headers: { Authorization: `Bearer ${authService.getJwt()}` },
    }
  );
  //   console.log("Counter Docs: ", counterDocs);
  return counterDocs.data.docs;
}

// Helper functions
const sendSMS = async (smsObj) => {
  if (smsObj.item.name !== "AxiosError") {
    // const sendSmsEndpoint =
    //   "https://sembracarebackend.mysembra.com/bandwidth/sendSMS"; // Local endpoint

    const sendSmsEndpoint = "/bandwidth/sendSMS"; // Production Endpoint

    const messageInfo = {
      messageUser: authService.getCurrentUser(),
      messageFrom: "+18448915624",
      messageTo: `+1${smsObj.mobileNumber.replace(/\D/g, "")}`,
      messageText:
        "Sign " +
        smsObj.title +
        " at https://app-42723.on-aptible.com/docsign/" +
        smsObj.item.data._id,
      messageDate: new Date().toLocaleDateString(),
    };

    const smsResponse = await http.post(sendSmsEndpoint, messageInfo, {
      headers: { Authorization: `Bearer ${authService.getJwt()}` },
    });
  }
};

export default {
  createDoc,
  checkDoc,
  resendDoc,
  getDoc,
  getCounterDocs,
  setFinalDoc,
};
