import React from "react";
import { FaUserCircle } from "react-icons/fa";
import Button from "../button";

export default function Success({ handleLogout }) {
  return (
    <main>
      <section>
        <h2>Success</h2>
        <p>
          Thank you for using the SembraCare Agency Portal to sign and submit
          these agreements. Please contact your SembraCare Success Team
          representative with any questions.
        </p>
        {/* <div> */}
        <p>
          Visit documents link under the "User Profile" icon{" "}
          <FaUserCircle style={{ color: "#0000c2", fontSize: "1.5rem" }} /> to
          view/download your signed Documents
        </p>
        {/* {viewportWidth < 700 && <DocDownloadMessage />} */}
        {/* </div> */}
        <Button label="Log Out" handleClick={handleLogout} />
      </section>
    </main>
  );
}
