import http from "./httpService";
import apiUrl from "../config.json";
import jwtDecode from "jwt-decode";

const apiEndpoint = apiUrl.apiUrl + "/auth";
const tokenKey = "token";

// http.setJwt(getJwt());

// export async function login(username, password) {
//     const loginURL = "https://usermgmt.mysembra.com/aws-auth/login";
//     const cognitoUser = {
//         Username: username,
//         Password: password,
//         realm: "SembraApplicant"
//     }
//     try {
//         return await http.post(loginURL, cognitoUser).then(async res => {
//             localStorage.setItem(tokenKey, username);
//             localStorage.setItem('token', JSON.stringify(res.data));
//             localStorage.setItem('userEmail', res.data.idToken.payload.email);
//             localStorage.setItem('userJwtToken', res.data.idToken.jwtToken);
//             localStorage.setItem('userAccessToken', res.data.accessToken.jwtToken);
//             localStorage.setItem('userRefreshToken', res.data.refreshToken.token);
//             return res.data;
//         }).catch(async err => {
//             return err.response.data.message;
//         })
//     }
//     catch {
//         return "Error in Sending";
//     };
// }

// export async function login(username, password) {
//     const userResponse = await http.post(apiEndpoint, { username, password });

//     if (userResponse.data.error) {
//         return userResponse.data.error;
//     } else {
//         localStorage.setItem(tokenKey, userResponse.data);
//         return null;
//     }
// }

function login(username, password) {
  const loginURL = "https://usermgmt.mysembra.com/aws-auth/login";
  const cognitoUser = {
    Username: username.toLowerCase(),
    Password: password,
    realm: "SembraDocument",
  };
  try {
    return http
      .post(loginURL, cognitoUser)
      .then(async (res) => {
        localStorage.setItem("documentUser", username.toLowerCase());
        localStorage.setItem("token", res.data.idToken);
        localStorage.setItem("realm", res.data.Realm);
        localStorage.setItem("userAccessToken", res.data.accessToken);
        localStorage.setItem("userRefreshToken", res.data.refreshToken);
        return res.data;
      })
      .catch(async (err) => {});
  } catch {
    return "Error in Sending";
  }
}

export function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

export function logout() {
  localStorage.clear();
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem("documentUser");
    // return jwtDecode(jwt).email;
    return jwt;
  } catch (ex) {
    return null;
  }
}

export function getUserData() {
  try {
    const userData = localStorage.getItem("userInfo");
    const storedUserData = JSON.parse(userData);
    // console.log("User Data: ", userData);
    return storedUserData.userData;
  } catch (ex) {
    return null;
  }
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export default {
  login,
  loginWithJwt,
  logout,
  getCurrentUser,
  getUserData,
  getJwt,
};
