import ReUserMessageact, { useState } from "react";
import Checkbox from "../../inputs/checkbox";
import SignatureFunctionality from "./signature-functionality";
import UserMessage from "../../user-message";
import sampleDoc from "../../../assets/pdfs/ParticipationAgreement.pdf";
import { getUserData } from "../../../services/authService";
import authService from "../../../services/authService";
import docService from "../../../services/docService";
import agencyService from "../../../services/agencyService";

export default function AttnDocsModalBody({
  agencyData,
  userData,
  selectedDocument,
  handleUserUpdate,
  closeModal,
  handleTimedMessage,
  taxIDFullList,
}) {
  const [sigSmsSent, setSigSmsSent] = useState(false);
  const [docId, setDocId] = useState(null);
  const [docTermsAccepted, setDocTermsAccepted] = useState(false);
  const [docSigned, setDocSigned] = useState(false);
  const [docTermsChecked, setDocTermsChecked] = useState(false);
  const [pdfDoc, setPdfDoc] = useState("");

  const handleCheckbox = () => {
    setDocTermsChecked(!docTermsChecked);
  };

  const handleSigSms = () => {
    const userData = authService.getUserData();
    setDocTermsAccepted(true);
    docService
      .createDoc(
        selectedDocument.docTitle,
        userData.name,
        selectedDocument.agencyName,
        userData.mobile,
        selectedDocument.agencyAddress
      )
      .then((newDoc) => {
        setDocId(newDoc.data._id);
        setSigSmsSent(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleDocSign = () => {
    setDocSigned(true);
  };

  const getSignedDoc = (signedDoc) => {
    setPdfDoc(signedDoc);
  };

  const submitFinalDoc = async () => {
    handleTimedMessage(
      `"${docNamePretty}" for TaxId ${selectedDocument.docTaxID} successfully signed`
    );
    const data = await docService.setFinalDoc(docId, {
      finalDoc: pdfDoc, // prod
      // finalDoc: "BAA base64 doc", // local
    });

    if (data.name === "AxiosError") {
      console.log("Error: ", data.response.data.message);
    } else {
      agencyData.map((agency, index) => {
        let existingContracts = agency.contractInfo;
        // console.log(agency.companyData.name, existingContracts);
        if (agency.companyData.name === selectedDocument.agencyName) {
          // if (!existingContracts) {
          //   let currentContractInfo = {
          //     contractInfo: { MessengerParticipation: [{ taxID: selectedDocument.docTaxID, Messenger: docId }] },
          //   };
          //   agencyService.saveAgencyData(
          //     agency._id,
          //     Object.assign(agency, currentContractInfo)
          //   );
          // } else {
          if (
            selectedDocument.docTitle ===
              "Sembra Messenger Participtation Contract" ||
            "Sembra Messsenger Updated Agreement"
          ) {
            if (existingContracts.MessengerParticipation.length == 0) {
              agencyService.saveAgencyData(agency._id, {
                contractInfo: {
                  ...agency.contractInfo,
                  MessengerParticipation: [
                    { taxID: selectedDocument.docTaxID, Messenger: docId },
                  ],
                },
              });
            } else {
              let newMessengerParticipation = [
                ...existingContracts.MessengerParticipation,
                { taxID: selectedDocument.docTaxID, Messenger: docId },
              ];
              agencyService.saveAgencyData(agency._id, {
                contractInfo: {
                  ...agency.contractInfo,
                  MessengerParticipation: newMessengerParticipation,
                },
              });
            }
          }
          if (
            selectedDocument.docTitle ===
            "SembraCare Updated Customer Service Agreement"
          ) {
            agencyService.saveAgencyData(agency._id, {
              contractInfo: {
                ...agency.contractInfo,
                UpdatedCSA: docId,
              },
            });
          }
          // }
        }
      });
      handleUserUpdate();
      closeModal();
    }
  };

  const docNamePretty = selectedDocument.docName.split(/(?=[A-Z])/).join(" ");

  const docTerms = `"I have read and understand the terms and conditions of the ${docNamePretty}. By checking this box I confirm that I am duly authorized to sign the ${docNamePretty} on behalf of Provider and legally empowered to contractually bind Provider to the ${docNamePretty} and related documents."`;

  return (
    <div>
      <div className="mb-md">
        <h3>{docNamePretty}</h3>
        <p>
          The {docNamePretty} is the updated agreement needed for your company
          to be in the SembraMessenger Network.
        </p>
        {/* <a target="_blank" href={sampleDoc}>
          Review Sample {docNamePretty} Document
        </a> */}
      </div>

      {!docSigned ? (
        // <div>
        <UserMessage
          message={`SembraCare will send a link to
             ${userData.mobile} via text message.
              Clicking this link will open a signature pad on your mobile
              device. If you agree to the terms of the ${docNamePretty}, check
              the checkbox below and sign your name using the link SembraCare
              sent via SMS/Text. After your signature is accepted, return to
              this page/window to add signature and finalize ${docNamePretty}.`}
          // message="old messsage commeneted out for now"
          variant="information"
        />
      ) : (
        <UserMessage
          message={`Your signature was successfully collected. Please click "Submit Final
        Document" button below to submit your signed document for review.`}
          variant="success"
        />
      )}

      {docTermsAccepted ? (
        <>
          {!docSigned ? (
            <UserMessage
              message={`Terms and conditions were accepted - Please check your mobile
           device to sign Business Associate Agreement.`}
              variant="success"
            />
          ) : (
            <UserMessage
              message={`Terms and conditions were accepted.`}
              variant="success"
            />
          )}
        </>
      ) : (
        <Checkbox label={docTerms} handleCheckbox={handleCheckbox} />
      )}

      <div className="text-center">
        {!sigSmsSent && (
          <button
            // className="sc-btn"
            className={docTermsChecked ? "sc-btn" : "disabled-btn"}
            disabled={docTermsChecked ? false : true}
            onClick={handleSigSms}
          >
            Send SMS to sign
          </button>
        )}
        {sigSmsSent && (
          // !docSigned &&
          <SignatureFunctionality
            userData={userData}
            selectedDocument={selectedDocument}
            docId={docId}
            onDocSign={handleDocSign}
            docSigned={docSigned}
            getSignedDoc={getSignedDoc}
            pdfDoc={pdfDoc}
            docNamePretty={docNamePretty}
            taxIDFullList={taxIDFullList}
          />
        )}
      </div>
      {docSigned && (
        <div className="text-center mt-xl">
          <button
            className="sc-btn"
            // onClick={handleSubmit}
            onClick={submitFinalDoc}
          >
            Submit Final Document
          </button>
        </div>
      )}
    </div>
  );
}
