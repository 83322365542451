import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import NumberFormat from "react-number-format";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import CheckboxInput from "../../inputs/checkbox";
import TextInput from "../../inputs/text-input";
import Button from "../../button";
import VerificationMessage from "../../verification-message";

import authService from "../../../services/authService";
import userService from "../../../services/userService";
import phoneService from "../../../services/phoneService";
import agencyService from "../../../services/agencyService";
import { useParams } from "react-router-dom";
import UserMessage from "../../user-message";
import requiredDocuments from "../../../assets/objects/requiredDocuments";

const LegacySignup = ({
  onUserUpdate,
  handleShowPassword,
  showPassword,
  handleNav,
}) => {
  const [inputData, setInputData] = useState({
    username: "",
    password: "",
    confirmPassword: "",
    userTitle: "",
    userFullName: "",
    // userEmail: "",
    userPhone: "",
    userJWT: "",
  });

  const [agencyError, setAgencyError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [agencyData, setAgencyData] = useState();
  const [disableUser, setDisableUser] = useState(true);
  const [isAuthorizedUser, setIsAuthorizedUser] = useState(false);
  const [readyForCredentials, setReadyForCredentials] = useState(false);

  const [disablePhone, setDisablePhone] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [phoneVerificationNumber, setPhoneVerificationNumber] = useState();
  const [showLoginLink, setShowLoginLink] = useState(false);
  const [isPwConfirmed, setIsPwConfirmed] = useState(false);
  const params = useParams();

  useEffect(() => {
    const getAgencyID = async (id) => {
      const { data: agencyData } = await agencyService.getAgencyID(params.id);

      if (
        agencyData == "No Agency Found" ||
        agencyData == "Valid User Already Registered"
      ) {
        setAgencyError(true);
        setShowLoginLink(true);
        if (agencyData == "No Agency Found") {
          setErrorMessage("No Agency Found");
          setShowLoginLink(false);
        } else if (agencyData == "Valid User Already Registered")
          setErrorMessage("Valid User Already Registered");
      } else {
        setAgencyData(agencyData);
      }

      return agencyData;
    };

    getAgencyID();

    if (agencyError) {
      window.scrollTo(0, 0);
    }
  }, [agencyError]);

  const handleAuthorizedUser = () => {
    setIsAuthorizedUser(!isAuthorizedUser);
    setDisableUser(!disableUser);
    setReadyForCredentials(!readyForCredentials);
  };

  let [showCredentials, setShowCredentials] = useState(null);
  const handleContinueToCredentials = () => {
    setShowCredentials(true);
  };

  let [validationMessage, setValidationMessage] = useState("");
  // const [mobileReqMet, setMobileReqMet] = useState(false);
  const handleInputChange = (key, value) => {
    // console.log("Key", key, "Value", value);
    setInputData({ ...inputData, [key]: value });
    const emailValidation =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (key === "username" && !emailValidation.test(value)) {
      setValidationMessage("Invalid Email");
    } else {
      setValidationMessage("");
    }
    // console.log(value.replace(/\D/g, "").length >= 10);
    // if (key === "userPhone" && value.replace(/\D/g, "").length >= 10) {
    //   setMobileReqMet(true);
    // } else {
    //   setMobileReqMet(false);
    // }

    if (
      key == "confirmPassword" ||
      (key == "password" && inputData.confirmPassword !== "")
    ) {
      if (inputData.password == value || inputData.confirmPassword == value) {
        setValidationMessage("Password Confirmed");
        setIsPwConfirmed(true);
      } else {
        setValidationMessage("Passwords must match");
        setIsPwConfirmed(false);
      }
    }
  };

  // const goToPersonal = async () => {
  //   setPhoneVerified(true);
  //   setDisablePhone(false);
  //   setAgencyError(false);
  // };

  const registerCognito = async () => {
    const cognitoResponse = await userService.signup(
      inputData.username,
      inputData.password,
      inputData.userFullName,
      inputData.userPhone,
      // inputData.userEmail,
      agencyData.agencyCode
    );
    if (cognitoResponse.status === "error") {
      setAgencyError(true);
      setErrorMessage(cognitoResponse.error.response.data.message);
    } else {
      setDisablePhone(true);
      setDisableUser(true);
      setAgencyError(false);
      setPhoneVerified(true);
    }

    // const send2FAResponse = await phoneService.send2FA(
    //   inputData.userPhone,
    //   inputData.username
    // );
    // console.log(send2FAResponse);
  };

  const verifyMobileCode = async () => {
    const cogitoVerify = await userService.verifyCognito(
      inputData.username,
      phoneVerificationNumber
    );

    if (cogitoVerify === "Error in Verification") {
      setAgencyError("Error in Verification");
      setErrorMessage(cogitoVerify);
    } else {
      const userHold = {
        username: inputData.username,
        password: inputData.password,
        userData: {
          mobile: inputData.userPhone,
          name: inputData.userFullName,
          title: inputData.userTitle,
        },
        agencyID: agencyData.id,
      };
      const addNewUser = await userService.registerLegacyUser(userHold);
      const login = await authService.login(
        inputData.username,
        inputData.password
      );
      await agencyService.saveAgencyData(agencyData.id, {
        messengerData: {
          eventDates: {
            userCreated: moment()
              .tz("America/New_York")
              .format("dddd, MMMM Do YYYY, h:mm:ss a"),
          },
        },
        // contractInfo: { MessengerParticipation: [] },
        contractInfo: { MessengerParticipation: [] },
      });

      if (login && addNewUser) {
        handleNav("", "Welcome");
        onUserUpdate();
      }
    }
  };

  return (
    <div className="login-content">
      <section className="form-wrapper">
        <h2>Legacy User Login</h2>

        {!showCredentials && (
          <>
            <div className="legacy-agency-info">
              <p>Welcome to SembraCare Provider Portal!</p>

              <p>
                From time to time, we need to get your signature on new
                agreements. Instead of using paper, we created this convenient
                digital portal to collect your signature on the new forms and
                agreements. Follow the simple instructions and the whole process
                can be completed in a few minutes.
              </p>
            </div>

            <hr />
          </>
        )}

        {agencyData && (
          <div>
            {!showCredentials && (
              <>
                <UserMessage
                  message="Please confirm you are an authorized user for the organization listed."
                  variant="information"
                />

                <div>
                  <strong>
                    <p>{agencyData.companyData.name}</p>
                    <p>{agencyData.companyData.city}</p>
                  </strong>
                  {/* <p>
                    <strong>Phone:</strong> {agencyData.companyData.phone}
                  </p>
                  <p style={{ maxWidth: "30%" }}>
                    <strong>Email:</strong>
                    <br />
                    {agencyData.companyData.email
                      ? agencyData.companyData.email.replace(/;/g, ",\n")
                      : "No Email Provided"}
                  </p> */}
                </div>

                <div>
                  <CheckboxInput
                    label="By clicking the box, you agree that you have authority to sign on behalf of your organization."
                    handleCheckbox={handleAuthorizedUser}
                    disableInput={disablePhone}
                  />
                  <p>
                    <strong>** Note:</strong> Registrant is authorized to sign
                    on behalf of the business.
                  </p>

                  <Button
                    label="Next"
                    disabledConditions={isAuthorizedUser ? false : true}
                    handleClick={handleContinueToCredentials}
                  />
                </div>
              </>
            )}

            {showCredentials && !disableUser && !phoneVerified && (
              <>
                <div className="information">
                  <UserMessage
                    message={`Please create an authorized user account by filling out the
                    information below.`}
                    variant="information"
                  />
                  <UserMessage
                    message={`Please enter "Title"" and "Name" as you want them to appear on the Participation Agreement`}
                    variant="information"
                  />
                  <UserMessage
                    message={`A verification code will be sent to the mobile number
                    provided.  Access to your mobile device is REQUIRED to complete certain tasks.`}
                    variant="information"
                  />
                  <UserMessage
                    message={`Passwords require a minimum of 8 characters, 1 uppercase letter and one number.  Passwords must match before you can add mobile number.`}
                    variant="information"
                  />
                </div>

                <TextInput
                  label="Email (Username)"
                  type="text"
                  handleInputChange={(e) =>
                    handleInputChange("username", e.target.value)
                  }
                  disableInput={disableUser}
                />

                <TextInput
                  label="Title"
                  type="text"
                  handleInputChange={(e) =>
                    handleInputChange("userTitle", e.target.value)
                  }
                  placeholder="CEO, President, Administrator, Owner, etc..."
                />

                <TextInput
                  label="Full Name"
                  type="text"
                  handleInputChange={(e) =>
                    handleInputChange("userFullName", e.target.value)
                  }
                />

                <TextInput
                  label="Password"
                  disableInput={disableUser}
                  type={showPassword ? "text" : "password"}
                  handleInputChange={(e) =>
                    handleInputChange("password", e.target.value)
                  }
                  icon={
                    !showPassword ? (
                      <FaEye className="icon" />
                    ) : (
                      <FaEyeSlash className="icon" />
                    )
                  }
                  handleShowPassword={handleShowPassword}
                />

                <TextInput
                  label="Confirm Password"
                  disableInput={disableUser}
                  type={showPassword ? "text" : "password"}
                  handleInputChange={(e) =>
                    handleInputChange("confirmPassword", e.target.value)
                  }
                  validationMessage={validationMessage}
                  validationSuccess={
                    validationMessage == "Password Confirmed" ? true : false
                  }
                  icon={
                    !showPassword ? (
                      <FaEye className="icon" />
                    ) : (
                      <FaEyeSlash className="icon" />
                    )
                  }
                  handleShowPassword={handleShowPassword}
                />

                {/* {validationMessage == "Password Confirmed" && ( */}
                <div className="form-group">
                  <label htmlFor="mobile">Mobile Number</label>
                  <NumberFormat
                    id="mobile"
                    type="text"
                    format="(###) ###-####"
                    mask="_"
                    minLength="17"
                    placeholder="(___) ___ - ____"
                    onChange={(e) =>
                      handleInputChange("userPhone", e.target.value)
                    }
                    disabled={disableUser || !isPwConfirmed}
                  ></NumberFormat>
                </div>
                {/* )} */}

                <Button
                  label="Verify Mobile #"
                  handleClick={registerCognito}
                  disabledConditions={
                    !inputData.userTitle ||
                    !inputData.userFullName ||
                    inputData.userPhone.replace(/\D/g, "").length < 10 ||
                    !isPwConfirmed
                  }
                />
              </>
            )}

            {disablePhone && phoneVerified && (
              <div>
                <UserMessage
                  message=" Please check your mobile device for your 6 digit verification
                  code"
                  variant="information"
                />

                <div>
                  <TextInput
                    label="Phone Verification"
                    type="text"
                    handleInputChange={(e) =>
                      setPhoneVerificationNumber(e.target.value)
                    }
                  />

                  <button
                    className="sc-btn"
                    type="button"
                    onClick={verifyMobileCode}
                  >
                    Verify Mobile Code
                  </button>

                  <hr />

                  <div className="text-center">
                    <p>
                      Didn't receive your verification code? Click "Reload"
                      button to re-enter information
                    </p>
                    <button
                      className="sc-btn"
                      onClick={() => window.location.reload()}
                    >
                      Reload
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {agencyError && (
          <VerificationMessage
            isSuccessMessage={false}
            userMessage={errorMessage}
          />
        )}
        {showLoginLink && (
          <div className="login-link">
            <a href="/">Click here to login</a>
          </div>
        )}
      </section>
    </div>
  );
};

export default LegacySignup;
